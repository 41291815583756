import axios from '@axios'
import baseConfig from '@/config/index'
const TICKET_SERVICE_PATH = baseConfig.BASE_PATH
const TEST_SERVICE_PATH = baseConfig.TEST_SERVICE_PATH

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchRefTags(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post(TEST_SERVICE_PATH + '/refTag/list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchRefTag(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .post(TEST_SERVICE_PATH + '/refTag/detail', { refTagId: id})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editRefTag(ctx, refTagData) {
      return new Promise((resolve, reject) => {
        axios
          .post(TEST_SERVICE_PATH + '/refTag/edit', refTagData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteRefTag(ctx, refTagData) {
      return new Promise((resolve, reject) => {
        axios
          .post(TEST_SERVICE_PATH + '/refTag/delete', refTagData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addRefTag(ctx, refTagData) {
      return new Promise((resolve, reject) => {
        axios
          .post(TEST_SERVICE_PATH + '/refTag/create', refTagData )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
