import { ref, watch } from '@vue/composition-api'
// import store from '@/store'

export default function useRefTagHandler(props, emit) {
    // ------------------------------------------------
    // taskLocal
    // ------------------------------------------------
    const refTagLocal = ref(JSON.parse(JSON.stringify(props.tag.value)))
    const resetRefTagLocal = () => {
      refTagLocal.value = JSON.parse(JSON.stringify(props.tag.value))
    }
    watch(props.tag, () => {
      resetRefTagLocal()
    })
  
    // ------------------------------------------------
    // isEventHandlerSidebarActive
    // * Clear form if sidebar is closed
    // ! We can hide it using @hidden event
    // ------------------------------------------------
    // watch(props.isEventHandlerSidebarActive, val => {
    //   // ? Don't reset event till transition is finished
    //   if (!val) {
    //     setTimeout(() => {
    //       clearForm.value()
    //     }, 350)
    //   }
    // })
  
    const onSubmit = () => {
      const refTagData = JSON.parse(JSON.stringify(refTagLocal))
  
      // * If event has id => Edit Event
      // Emit event for add/update event
      if (props.tag.value.id) emit('update-ref-tag', refTagData.value)
      else emit('add-ref-tag', refTagData.value)
  
      // Close sidebar
      emit('update:is-add-new-ref-tag-sidebar-active', false)
    }
  
  
    return {
      // fetchCategories,
      // fetchProducts,
      // fetchTags,
      // fetchPriorities,
      // fetchTeams,
      // fetchUsers,
      refTagLocal,
      resetRefTagLocal,
      onSubmit,
    }
  }